/**
 * External Dependencies
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";

/**
 * Internal Components
 */
import Button from "../../shared/button";
import DiagnosesItem from "./diagnosesitem";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Services and Utilities
 */
import "./framediagnoses.css";
import { setNotification, setVisit } from "../../../redux/actions";
import { generateDiagnoses } from "../../../scripts/generateDiagnoses";
import { generateId } from "../../../scripts/apiService";

/**
 * FrameDiagnoses component for displaying and managing diagnoses.
 * @param {object} props - Component props
 * @param {string} [props.rootClassName] - Additional root class names
 * @returns {JSX.Element} FrameDiagnoses component
 */
const FrameDiagnoses = ({ rootClassName }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @userData - User data from the global state.
   * @currentVisitId - Current visit ID from the global state.
   * @currentVisit - Current visit based on the current visit ID.
   * @diagnoses - Diagnoses of the current visit.
   * @problemButtonIconSrc - State for problem button icon source.
   * @setProblemButtonIconSrc - Function to update problem button icon source.
   * @diagnosisButtonIconSrc - State for diagnosis button icon source.
   * @setDiagnosisButtonIconSrc - Function to update diagnosis button icon source.
   * @selectedCount - Length of diagnosis selected
   * @setSelectedCount - Function to set the length of diagnosis selected
   */
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.globalData.userData);
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentVisit =
    userData.visits?.find((visit) => visit._id === currentVisitId) || null;
  const diagnoses = currentVisit?.diagnoses || [];
  const [problemButtonIconSrc, setProblemButtonIconSrc] =
    useState("/icons/plus.svg");
  const [diagnosisButtonIconSrc, setDiagnosisButtonIconSrc] =
    useState("/icons/plus.svg");
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    /**
     * Handles selectedcount length
     */
    const count = diagnoses.filter(
      (diagnosis) => diagnosis.diagnosesSelected,
    ).length;
    setSelectedCount(count);
  }, [diagnoses]);

  useEffect(() => {
    /**
     * Handles diagnosis generation based on visit state and transcript length.
     */
    if (currentVisit && !currentVisit.visitDiagnosesState) {
      if (currentVisit.visitTranscript.split(" ").length < 10) {
        dispatch(
          setVisit(currentVisitId, {
            visitDiagnosesState: "COMPLETE",
            diagnoses: [
              {
                _id: generateId(),
                diagnosesIdentifier: "INCOMPLETE",
                diagnosesName: "Insufficient Transcript",
                diagnosesDescription:
                  "The transcript was insufficient. There were no diagnoses identified. Please try again with another visit.",
              },
            ],
          }),
        );
      } else {
        dispatch(
          setVisit(currentVisitId, { visitDiagnosesState: "INCOMPLETE" }),
        );
      }
    } else if (currentVisit.visitDiagnosesState === "INCOMPLETE") {
      dispatch(setVisit(currentVisitId, { visitDiagnosesState: "GENERATING" }));
      dispatch(
        setNotification({
          name: "Recommending Diagnoses",
          description: "Estimated 10-20 seconds",
          status: "info",
          duration: 5000,
          isClosable: true,
        }),
      );
      generateDiagnoses(
        currentVisitId,
        currentVisit.visitTranscript,
        dispatch,
        setVisit,
        setNotification,
      );
    }
  }, [currentVisit]);

  /**
   * Handles copy all button click event to copy diagnoses or problems to clipboard.
   * @param {string} type - Either "PROBLEM" or "DIAGNOSES" to prepend to the clipboard content.
   */
  const handleUpdateProblemList = (type) => {
    dispatch(
      setNotification({
        name: "Sync to Epic",
        description:
          "Inside an Epic note, use Ctrl + Alt + J on Windows or Cmd + Option + J on macOS.",
        status: "info",
        duration: 7000,
        isClosable: true,
      }),
    );

    if (type === "PROBLEM") {
      setProblemButtonIconSrc("/icons/checkmark.svg");
      setTimeout(() => {
        setProblemButtonIconSrc("/icons/plus.svg");
      }, 2000);
    } else if (type === "DIAGNOSIS") {
      setDiagnosisButtonIconSrc("/icons/checkmark.svg");
      setTimeout(() => {
        setDiagnosisButtonIconSrc("/icons/plus.svg");
      }, 2000);
    }

    const formattedText = diagnoses
      .filter((diagnosis) => diagnosis.diagnosesSelected)
      .map(
        (diagnosis) =>
          `${diagnosis.diagnosesIdentifier}\n${diagnosis.diagnosesName}\n${diagnosis.diagnosesDescription}\n`,
      )
      .join("\n");

    const finalText = `${type}\n${formattedText}`;

    navigator.clipboard
      .writeText(finalText)
      .then(() => {
        console.log(`${type} copied to clipboard`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className={`framediagnoses-framediagnoses ${rootClassName}`}>
      <div className="framediagnoses-diagnoseslist">
        {diagnoses.length > 0 ? (
          diagnoses.map((diagnosis, index) => (
            <DiagnosesItem
              key={index}
              id={diagnosis._id}
              identifier={diagnosis.diagnosesIdentifier}
              name={diagnosis.diagnosesName}
              description={diagnosis.diagnosesDescription}
              rootClassName={rootClassName}
            />
          ))
        ) : currentVisit?.visitDiagnosesState === "COMPLETE" ? (
          <DiagnosesItem
            identifier="INCOMPLETE"
            id="INCOMPLETE_ID"
            name="Insufficient Transcript"
            description="The transcript was insufficient. There were no diagnoses identified. Please try again with another visit."
            rootClassName={rootClassName}
          />
        ) : (
          <>
            {[...Array(3)].map((_, index) => (
              <div key={index} className="diagnosesitem-diagnosesitem">
                <div className="diagnosesitem-diagnosescontainer card1">
                  <Skeleton width="20%" className="mb-2" />
                  <Skeleton width="40%" className="mb-2" />
                  <div className="row">
                    <Skeleton width="80%" className="mb-2" />
                    <Skeleton width="20%" className="mb-2" />
                  </div>
                  <div className="row">
                    <Skeleton width="30%" className="mb-2" />
                    <Skeleton width="30%" className="mb-2" />
                    <Skeleton width="40%" className="mb-2" />
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="framediagnoses-buttoncontainer">
        <Button
          text={`Problem List`}
          rootClassName="button-root-class-name1"
          className=""
          onClick={() => handleUpdateProblemList("PROBLEM")}
          image={problemButtonIconSrc}
        />
        <Button
          text={`Visit Diagnoses`}
          rootClassName="button-root-class-name1"
          className=""
          onClick={() => handleUpdateProblemList("DIAGNOSIS")}
          image={diagnosisButtonIconSrc}
        />
      </div>
    </div>
  );
};

FrameDiagnoses.defaultProps = {
  rootClassName: "",
};

FrameDiagnoses.propTypes = {
  rootClassName: PropTypes.string,
};

export default FrameDiagnoses;
