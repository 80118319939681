/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

/**
 * Internal Components
 */
import IconHover from "../../shared/iconhover";
import DarkButton from "../../shared/darkbutton";
import Button from "../../shared/button";
import VisitItemList from "./visititemlist";
import SidebarExternalLinks from "./sidebarexternallinks";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Utilities
 */
import "./sidebar.css";
import {
  setCurrentState,
  addVisit,
  upsertUserStats,
} from "../../../redux/actions";
import { generateId, getCurrentDate } from "../../../scripts/apiService";

/**
 * Sidebar component for displaying sidebar navigation and recent visits.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for custom styling.
 * @returns {JSX.Element} The Sidebar component.
 */
const Sidebar = ({ rootClassName }) => {
  const dispatch = useDispatch();

  /**
   * Handles template center button click event.
   */
  const handleTemplateCenterClicked = () => {
    dispatch(
      setCurrentState({
        currentVisitId: "",
        currentNoteId: "",
        currentTab: "TCENTER",
      }),
    );
    if (window.innerWidth <= 740) {
      handleSidebarClicked();
    }
  };

  /**
   * Handles the click event to hide the sidebar.
   */
  const handleSidebarClicked = () => {
    dispatch(setCurrentState({ currentDisplaySidebar: false }));
  };

  /**
   * Formats the given date into a readable string.
   * @param {Date} date - The date to format.
   * @returns {string} - The formatted date and time string.
   */
  const formatDateTime = (date) => {
    const dateOptions = { month: "long", day: "numeric", year: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date,
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date,
    );
    return `${formattedDate} • ${formattedTime}`;
  };

  /**
   * Handles the click event to create a new visit.
   */
  const handleNewVisitClicked = () => {
    const newVisitId = generateId();
    const newVisit = {
      _id: newVisitId,
      visitName: "New Encounter",
      visitCreatedDate: formatDateTime(new Date()),
      visitLanguage: "ENGLISH",
      visitAudioTime: 0,
      visitTranscript: "",
      visitAdditionalPatientContext: "",
      visitFinished: false,
      visitTypeId: "",
      visitTypeUptoDate: true,
      visitCopyMetric: 0,
      visitDiagnosesState: "INCOMPLETE",
      notes: [],
      diagnoses: [],
    };
    dispatch(addVisit(newVisit));
    dispatch(
      upsertUserStats({
        userStatsDate: getCurrentDate(),
        userStatsVisitsCreated: 1,
      }),
    );
    dispatch(
      setCurrentState({
        currentVisitId: newVisitId,
        currentTab: "RECORD",
        currentNoteId: "",
      }),
    );
    if (window.innerWidth <= 740) {
      handleSidebarClicked();
    }
  };

  return (
    <div className={`sidebar-sidebar ${rootClassName}`}>
      <div className="sidebar-container">
        <div className="sidebar-header">
          <img
            alt="image"
            src="/icons/halologo.svg"
            className="sidebar-image"
          />
          <span className="sidebar-text td4"> </span>
          <IconHover
            imageSrc="/icons/sidebar.svg"
            rootClassName="iconhover-root-class-name3"
            className=""
            onClick={handleSidebarClicked}
          ></IconHover>
        </div>
        <DarkButton
          rootClassName="darkbutton-root-class-name2"
          text="New visit"
          image="/icons/plus1.svg"
          className=""
          onClick={handleNewVisitClicked}
        ></DarkButton>
        {window.innerWidth > 740 && (
          <Button
            text="Template center"
            rootClassName="templatecenter-button"
            className=""
            onClick={handleTemplateCenterClicked}
            image={"/icons/rocket.svg"}
          />
        )}
        <VisitItemList className=""></VisitItemList>
      </div>
      <SidebarExternalLinks className=""></SidebarExternalLinks>
    </div>
  );
};

Sidebar.defaultProps = {
  rootClassName: "",
};

Sidebar.propTypes = {
  rootClassName: PropTypes.string,
};

export default Sidebar;
