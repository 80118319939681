/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

/**
 * Internal Components
 */
import IconHover from "../../shared/iconhover";
import VisitNameContainer from "./visitnamecontainer";
import UnselectedTab from "./unselectedtab";
import SelectedTab from "./selectedtab";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Services and Utilities
 */
import "./tabbar.css";
import {
  setCurrentState,
  addVisit,
  upsertUserStats,
} from "../../../redux/actions";
import { generateId, getCurrentDate } from "../../../scripts/apiService";

/**
 * TabBar Component
 * @returns {JSX.Element} TabBar component
 */
const TabBar = () => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentTab - Current tab from the global state.
   * @currentDisplaySidebar - State for showing the sidebar from the global state.
   */
  const dispatch = useDispatch();
  const currentTab = useSelector(
    (state) => state.globalData.currentState.currentTab,
  );
  const currentDisplaySidebar = useSelector(
    (state) => state.globalData.currentState.currentDisplaySidebar,
  );
  const userName = useSelector((state) => state.globalData.userData.userName);

  /**
   * Handles the click event to show the sidebar.
   */
  const handleSidebarClicked = () => {
    dispatch(setCurrentState({ currentDisplaySidebar: true }));
  };

  /**
   * Handles the click event to change the current tab.
   * @param {string} tab - The tab to switch to.
   */
  const handleTabClick = (tab) => {
    if (tab !== "OUTBOUND") {
      dispatch(setCurrentState({ currentTab: tab }));
    }
  };

  /**
   * Formats the given date into a readable string.
   * @param {Date} date - The date to format.
   * @returns {string} - The formatted date and time string.
   */
  const formatDateTime = (date) => {
    const dateOptions = { month: "long", day: "numeric", year: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date,
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date,
    );
    return `${formattedDate} • ${formattedTime}`;
  };

  /**
   * Handles the click event to create a new visit.
   */
  const handleNewVisitClicked = () => {
    const newVisitId = generateId();
    const newVisit = {
      _id: newVisitId,
      visitName: "New Encounter",
      visitCreatedDate: formatDateTime(new Date()),
      visitLanguage: "ENGLISH",
      visitAudioTime: 0,
      visitTranscript: "",
      visitAdditionalPatientContext: "",
      visitFinished: false,
      visitTypeId: "",
      visitTypeUptoDate: true,
      visitCopyMetric: 0,
      visitDiagnosesState: "INCOMPLETE",
      notes: [],
      diagnoses: [],
    };
    dispatch(addVisit(newVisit));
    dispatch(
      upsertUserStats({
        userStatsDate: getCurrentDate(),
        userStatsVisitsCreated: 1,
      }),
    );
    dispatch(
      setCurrentState({
        currentVisitId: newVisitId,
        currentTab: "RECORD",
        currentNoteId: "",
      }),
    );
  };

  /**
   * Handles the click event to log out the user.
   */
  const handleLogoutClick = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  };

  /**
   * Handles the click event to switch to the TCENTER tab.
   */
  const handleTemplatesClick = () => {
    dispatch(
      setCurrentState({
        currentVisitId: "",
        currentNoteId: "",
        currentTab: "TCENTER",
      }),
    );
  };

  return (
    <div
      className={`tabbar-tabbar ${currentDisplaySidebar ? "tabbar-hide" : ""}`}
    >
      <div className="tabbar-one">
        {!currentDisplaySidebar && (
          <>
            <IconHover
              imageSrc="/icons/sidebar.svg"
              rootClassName="iconhover-root-class-name1"
              onClick={handleSidebarClicked}
            />
            {currentTab !== "TCENTER" && currentTab !== "TEMPLATES" && (
              <IconHover
                imageSrc="/icons/plus.svg"
                rootClassName="iconhover-root-class-name2"
                onClick={handleNewVisitClicked}
              />
            )}
          </>
        )}
        {currentTab === "TEMPLATES" && window.innerWidth > 740 && (
          <span
            className="tabbar-templatelabel tl4"
            onClick={handleTemplatesClick}
          >
            Templates /
          </span>
        )}
        {currentTab === "TCENTER" && (
          <span
            className="tabbar-templatelabel tl4"
            onClick={handleTemplatesClick}
          >
            Templates
          </span>
        )}
        {currentTab !== "TCENTER" && <VisitNameContainer />}
      </div>
      {window.innerWidth <= 740 ? (
        <></>
      ) : (
        <div className="tabbar-two">
          {currentTab !== "TCENTER" &&
            currentTab !== "TEMPLATES" &&
            currentTab !== "RECORD" &&
            ["TRANSCRIPT", "NOTES", "DIAGNOSES"].map((tab) =>
              currentTab === tab ? (
                <SelectedTab
                  key={tab}
                  text={tab.charAt(0) + tab.slice(1).toLowerCase()}
                  image={`/icons/${tab.toLowerCase()}.svg`}
                  onClick={() => handleTabClick(tab)}
                />
              ) : (
                <UnselectedTab
                  key={tab}
                  text={tab.charAt(0) + tab.slice(1).toLowerCase()}
                  image={`/icons/${tab.toLowerCase()}.svg`}
                  onClick={() => handleTabClick(tab)}
                />
              ),
            )}
        </div>
      )}
      <div className="tabbar-three">
        <span className="tabbar-templatelabel tl4">{userName}</span>
        <IconHover
          imageSrc="/icons/logout.svg"
          rootClassName="iconhover-root-class-name4"
          onClick={handleLogoutClick}
        />
      </div>
    </div>
  );
};

TabBar.propTypes = {
  visitname: PropTypes.string,
};

export default TabBar;
