import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "../../shared/dropdown/dropdown";
import SubButton from "../../shared/subbutton";
import {
  setCurrentState,
  setVisit,
  setNotification,
} from "../../../redux/actions";
import "./notetypecontainer.css";

/**
 * NoteTypeContainer Component
 *
 * This component handles the selection and display of visit types (templates) for a user. It provides a dropdown interface for users to select or change the current template. The component automatically selects the user's default template if no template is selected yet.
 *
 * @param {string} text - The text label to display within the container.
 *
 * @returns {JSX.Element} The NoteTypeContainer component.
 *
 * @dispatch - Redux dispatch function to manage state updates.
 * @userData - User-specific data from the global state, including the default template ID.
 * @currentVisitId - The ID of the current visit, fetched from global state.
 * @visits - The list of visits fetched from the global state.
 * @visitTypes - The list of visit types (templates) fetched from the global state.
 * @userDefaultTemplateId - The ID of the default template for the user.
 * @currentVisit - The current visit object based on the current visit ID.
 * @selectedVisitTypeId - The state that tracks the selected visit type ID.
 * @showDropdown - The state that manages the visibility of the dropdown menu.
 * @dropdownRef - Reference to the dropdown DOM element for handling outside clicks.
 */

const NoteTypeContainer = ({ text }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.globalData.userData);
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const visits = useSelector((state) => state.globalData.userData.visits);
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );

  const userDefaultTemplateId = userData?.userDefaultTemplate || "";

  const currentVisit = visits.find((visit) => visit._id === currentVisitId);
  const [selectedVisitTypeId, setSelectedVisitTypeId] = useState(
    currentVisit.visitTypeId || userDefaultTemplateId || "",
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    /**
     * Updates the selected visit type ID when the current visit or visit types change.
     * If no visit type is selected, fall back to the default template.
     */
    if (currentVisit.visitTypeId === "" && userDefaultTemplateId !== "") {
      setSelectedVisitTypeId(userDefaultTemplateId);
      dispatch(
        setVisit(currentVisitId, { visitTypeId: userDefaultTemplateId }),
      );
    } else {
      setSelectedVisitTypeId(currentVisit.visitTypeId);
    }
  }, [
    currentVisit.visitTypeId,
    visitTypes,
    userDefaultTemplateId,
    currentVisitId,
    dispatch,
  ]);

  useEffect(() => {
    /**
     * Handles click outside event to close the dropdown.
     */
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSelectChange = (newVisitTypeId) => {
    if (newVisitTypeId === "new-visit-type") {
      dispatch(
        setCurrentState({
          currentVisitId: "",
          currentNoteId: "",
          currentTab: "TCENTER",
        }),
      );
      return;
    }
    setSelectedVisitTypeId(newVisitTypeId);
    dispatch(setVisit(currentVisitId, { visitTypeId: newVisitTypeId }));
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const truncateVisitTypeName = (name) => {
    return name.length > 14 ? `${name.substring(0, 14)}...` : name;
  };

  const handleNewVisitTypeClick = () => {
    if (!userData.userIsRecording) {
      dispatch(
        setCurrentState({
          currentVisitId: "",
          currentNoteId: "",
          currentTab: "TCENTER",
        }),
      );
      setShowDropdown(false);
    } else {
      dispatch(
        setNotification({
          name: "Recording in Progress",
          description:
            "You cannot leave this screen until you finish a recording.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        }),
      );
    }
  };

  return (
    <div className="notetypecontainer-notetypecontainer">
      <span className="notetypecontainer-text tl1 type-text">{text}</span>
      <span className="spacer"></span>
      <div>
        <SubButton
          image="/icons/down.svg"
          text={truncateVisitTypeName(
            visitTypes.find(
              (visitType) => visitType._id === selectedVisitTypeId,
            )?.visitTypeName || "Templates",
          )}
          onClick={toggleDropdown}
          textFirst={true}
        ></SubButton>

        {showDropdown && (
          <div className="zero notetypecontainer-dropdown" ref={dropdownRef}>
            <Dropdown
              buttonList={[]}
              sectionList={[
                {
                  name: "TEMPLATES",
                  labelImage: "/icons/plus.svg",
                  labelAction: handleNewVisitTypeClick,
                  options: visitTypes
                    .filter(
                      (visitType) =>
                        visitType.visitTypeState !== "CREATING" &&
                        visitType.visitTypeState !== "ERROR",
                    )
                    .sort((a, b) => {
                      if (
                        a.visitTypeState === "DEFAULT" &&
                        b.visitTypeState !== "DEFAULT"
                      )
                        return 1;
                      if (
                        a.visitTypeState !== "DEFAULT" &&
                        b.visitTypeState === "DEFAULT"
                      )
                        return -1;
                      return 0;
                    })
                    .map((visitType) => ({
                      text:
                        visitType.visitTypeState === "DEFAULT"
                          ? `${visitType.visitTypeName} (HALO)`
                          : visitType.visitTypeName,
                      image: "/icons/checkmark.svg",
                      onClick: () => handleSelectChange(visitType._id),
                      selected: visitType._id === selectedVisitTypeId,
                    })),
                },
              ]}
              showBorder={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

NoteTypeContainer.defaultProps = {
  text: "Set template",
};

NoteTypeContainer.propTypes = {
  text: PropTypes.string,
};

export default NoteTypeContainer;
