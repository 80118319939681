/**
 * External Dependencies
 */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

/**
 * Internal Components
 */
import CreateNewTemplateItem from "./createnewtemplateitem";
import TemplateItem from "./templateitem";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 */
import "./frametcenter.css";
import {
  setCurrentState,
  addVisitType,
  deleteVisitType,
} from "../../../redux/actions";

/**
 * FrameTCenter Component
 * @returns {JSX.Element} FrameTCenter component
 */
const FrameTCenter = () => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @visitTypes - Visit types from the global state.
   */
  const dispatch = useDispatch();
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );

  /**
   * Handles the creation of a new template.
   */
  const handleCreateNewTemplateClick = () => {
    const newVisitTypeId = uuidv4();
    const newVisitType = {
      _id: newVisitTypeId,
      visitTypeName: "Untitled Template",
      visitTypeDescription: "",
      visitTypeBody: "",
      visitTypeBodyType: "TEMPLATE",
      visitTypeInstructions: "",
      visitTypeUsage: 0,
      visitTypeState: "INCOMPLETE",
      noteTypes: [],
    };
    dispatch(addVisitType(newVisitType));
    dispatch(
      setCurrentState({
        currentVisitTypeId: newVisitTypeId,
        currentVisitId: "",
        currentNoteId: "",
        currentTab: "TEMPLATES",
      }),
    );
  };

  /**
   * Handles the deletion of a template.
   * @param {string} visitTypeId - The ID of the visit type to be deleted.
   */
  const handleDeleteTemplate = (visitTypeId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this template?",
    );
    if (isConfirmed) {
      dispatch(deleteVisitType(visitTypeId));
    }
  };

  /**
   * Handles the editing of a template.
   * @param {string} visitTypeId - The ID of the visit type to be edited.
   */
  const handleEditTemplate = (visitTypeId) => {
    dispatch(
      setCurrentState({
        currentVisitId: "",
        currentNoteId: "",
        currentVisitTypeId: visitTypeId,
        currentTab: "TEMPLATES",
      }),
    );
  };

  /**
   * Sort visitTypes to place 'DEFAULT' state templates at the top.
   * @param {array} visitTypes - Array of visit types to be sorted.
   * @returns {array} Sorted array of visit types.
   */
  const sortVisitTypes = (visitTypes) => {
    return visitTypes.sort((a, b) => {
      if (a.visitTypeState === "DEFAULT" && b.visitTypeState !== "DEFAULT")
        return -1;
      if (a.visitTypeState !== "DEFAULT" && b.visitTypeState === "DEFAULT")
        return 1;
      return 0;
    });
  };

  const sortedVisitTypes = sortVisitTypes(visitTypes);

  return (
    <div className="frametcenter-frametcenter">
      <CreateNewTemplateItem onClick={handleCreateNewTemplateClick} />
      <div className="frametcenter-templateitemlist">
        {sortedVisitTypes.map((visitType) => (
          <TemplateItem
            key={visitType._id}
            id={visitType._id}
            name={visitType.visitTypeName}
            numberOfVisits={visitType.visitTypeUsage}
            visitTypeState={visitType.visitTypeState}
            bodyType={visitType.visitTypeBodyType}
            onClick={() => handleEditTemplate(visitType._id)}
            onDelete={() => handleDeleteTemplate(visitType._id)}
          />
        ))}
      </div>
    </div>
  );
};

export default FrameTCenter;
