/**
 * Initial state for the Redux store.
 */
export const initialState = {
  currentState: {
    currentSessionId: "",
    currentVisitId: "",
    currentNoteId: "",
    currentVisitTypeId: "",
    currentTab: "",
    currentDisplaySidebar: true,
    currentNotifications: {
      name: "",
      description: "",
      status: "",
      duration: 3000,
      isClosable: true,
    },
  },
  userData: {
    _id: "",
    userName: "",
    userEmail: "",
    userPassword: "",
    userIsRecording: false,
    userDefaultTemplate: "",
    userStats: [
      {
        userStatsDate: "",
        userStatsVisitsCreated: "",
        userStatsVisitsCompleted: "",
        userStatsTotalAudioTime: "",
        userStatsCopyMetric: "",
        userStatsNoteModification: "",
      },
    ],
    visits: [
      {
        _id: "",
        visitName: "",
        visitCreatedDate: "",
        visitLanguage: "ENGLISH",
        visitAudioTime: 0,
        visitTranscript: "",
        visitAdditionalPatientContext: "",
        visitFinished: false,
        visitTypeId: "",
        visitTypeUptoDate: true,
        visitCopyMetric: 0,
        visitDiagnosesState: "INCOMPLETE",
        notes: [
          {
            _id: "",
            noteName: "",
            noteBody: "",
            noteTypeId: "",
            noteIsVisible: true,
            noteFirstTimeGenerated: true,
          },
        ],
        diagnoses: [
          {
            _id: "",
            diagnosesIdentifier: "",
            diagnosesName: "",
            diagnosesDescription: "",
            diagnosesSelected: false,
          },
        ],
      },
    ],
    visitTypes: [
      {
        _id: "",
        visitTypeName: "",
        visitTypeDescription: "",
        visitTypeBody: "",
        visitTypeBodyType: "EXAMPLE",
        visitTypeInstructions: "",
        visitTypeUsage: "",
        visitTypeState: "READY",
        noteTypes: [
          {
            _id: "",
            noteTypeName: "",
            noteTypeInstructions: "",
          },
        ],
      },
    ],
  },
};
