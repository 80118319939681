/**
 * External Dependencies
 */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import CircleIconDark from "../../shared/circleicondark";
import AiPrompt from "./aiprompt";

/**
 * Internal Dependencies
 * - Styles
 * - PrimeReact Components
 * - Redux Actions
 * - Services and Utilities
 */
import "./selectednote.css";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { useSelector, useDispatch } from "react-redux";
import {
  setNote,
  setNotification,
  setVisit,
  upsertUserStats,
} from "../../../redux/actions";
import { generateNote } from "../../../scripts/generateNotes";
import { getCurrentDate } from "../../../scripts/apiService";

/**
 * SelectedNote component for displaying and editing a selected note.
 * @param {object} props - The component props.
 * @param {string} props.title - The title of the note.
 * @param {string} props.body - The body of the note.
 * @param {function} props.onClick - The function to handle the click event.
 * @returns {JSX.Element} The SelectedNote component.
 */
const SelectedNote = ({ title, body, onClick }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @currentNoteId - Current note ID from the global state.
   * @userData - User data from the global state.
   * @editableBody - State for the editable body of the note.
   * @setEditableBody - Function to update the editable body state.
   * @copyImageSrc - State for the copy icon source.
   * @setCopyImageSrc - Function to update the copy icon source.
   * @isLoading - State for the loading status.
   * @setIsLoading - Function to update the loading status.
   * @isNoteGenerating - State for the note generating status.
   * @setIsNoteGenerating - Function to update the note generating status.
   * @textareaRef - Reference to the textarea DOM element.
   * @editableBodyRef - Reference to the editable body state.
   */
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentNoteId = useSelector(
    (state) => state.globalData.currentState.currentNoteId,
  );
  const userData = useSelector((state) => state.globalData.userData);

  const [editableBody, setEditableBody] = useState(body);
  const [copyImageSrc, setCopyImageSrc] = useState("/icons/copy.svg");
  const [isLoading, setIsLoading] = useState(false);
  const [isNoteGenerating, setIsNoteGenerating] = useState(false);
  const textareaRef = useRef(null);
  const editableBodyRef = useRef(editableBody);

  const getVisitById = (visitId) => {
    return userData.visits.find((visit) => visit._id === visitId);
  };

  const getNoteById = (visitId, noteId) => {
    const visit = getVisitById(visitId);
    return visit.notes.find((note) => note._id === noteId);
  };

  const getVisitTypeById = (visitTypeId) => {
    return userData.visitTypes.find((type) => type._id === visitTypeId);
  };

  const getNoteTypeById = (visitType, noteTypeId) => {
    return visitType.noteTypes.find((noteType) => noteType._id === noteTypeId);
  };

  useEffect(() => {
    /**
     * Saves the note when the editable body or IDs change.
     */
    const saveNote = async () => {
      dispatch(
        setNote(currentVisitId, currentNoteId, { noteBody: editableBody }),
      );
    };
    saveNote();
    adjustTextareaHeight();
  }, [editableBody, currentVisitId, currentNoteId]);

  useEffect(() => {
    /**
     * Adjusts the textarea height on initial load.
     */
    adjustTextareaHeight();
  }, []);

  useEffect(() => {
    /**
     * Updates the editable body when the body prop changes.
     */
    setEditableBody(body);
    editableBodyRef.current = body;
  }, [body]);

  /**
   * Handles the AI prompt submission.
   * @param {string} message - The message from the AI prompt.
   */
  const handleAskAiSubmitClick = async (message) => {
    setIsNoteGenerating(true);
    setIsLoading(true);
    try {
      const currentVisit = getVisitById(currentVisitId);
      const visitType = getVisitTypeById(currentVisit.visitTypeId);
      const note = getNoteById(currentVisitId, currentNoteId);
      const noteType = getNoteTypeById(visitType, note.noteTypeId);

      await generateNote(
        currentVisitId,
        currentNoteId,
        currentVisit.visitTranscript,
        currentVisit.visitAdditionalPatientContext,
        visitType.visitTypeBodyType,
        noteType.noteTypeInstructions,
        editableBody,
        message,
        "SONNET",
        dispatch,
        setIsLoading,
      );
    } catch (error) {
      console.error("Error creating thread or generating note:", error);
      dispatch(
        setNotification({
          name: "ERROR",
          description: "Something went wrong. Try again!",
          status: "error",
          duration: 5000,
          isClosable: true,
        }),
      );
    } finally {
      setIsNoteGenerating(false);
    }
  };

  /**
   * Handles the regenerate icon click.
   */
  const handleRegenerateClick = () => {
    handleAskAiSubmitClick("regenerate the note");
  };

  /**
   * Handles changes to the editable body textarea.
   * @param {object} event - The change event.
   */
  const handleBodyChange = (event) => {
    setEditableBody(event.target.value);
    editableBodyRef.current = event.target.value;
    adjustTextareaHeight();
  };

  /**
   * Adjusts the height of the textarea based on its content.
   */
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  /**
   * Handles the copy icon click to copy the note body to the clipboard.
   */
  const handleCopyClick = async () => {
    dispatch(
      setVisit(currentVisitId, {
        visitCopyMetric:
          (getVisitById(currentVisitId).visitCopyMetric || 0) + 0.001,
      }),
    );

    dispatch(
      upsertUserStats({
        userStatsDate: getCurrentDate(),
        userStatsCopyMetric: 0.001,
      }),
    );

    try {
      await navigator.clipboard.writeText(
        `${title.toUpperCase()}:\n${editableBodyRef.current}`,
      );
      setCopyImageSrc("/icons/checkmark.svg");
      setTimeout(() => {
        setCopyImageSrc("/icons/copy.svg");
      }, 2000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  useEffect(() => {
    /**
     * Updates the editable body when the note changes.
     */
    const note = getNoteById(currentVisitId, currentNoteId);
    if (note.noteBody !== editableBodyRef.current) {
      setEditableBody(note.noteBody);
      adjustTextareaHeight();
    }
  }, [currentVisitId, currentNoteId]);

  return (
    <div className="selectednote-selectednote">
      <CircleIconDark
        imageSrc="/icons/pencil.svg"
        rootClassName="circleicondark-root-class-name1"
        onClick={onClick}
      />
      <div className="selectednote-notecontainer card1">
        <div className="selectednote-noteheader">
          <span className="td3">{title.toUpperCase()}</span>
          <img
            alt="copy"
            src={copyImageSrc}
            className="selectednote-image img2 copy-icon"
            onClick={handleCopyClick}
            data-pr-tooltip="Copy"
            data-pr-position="top"
          />
          <Tooltip target=".copy-icon" className="custom-tooltip" />
          <img
            alt=""
            src="/icons/redo.svg"
            className="selectednote-image img2 regenerate-icon"
            onClick={handleRegenerateClick}
            data-pr-tooltip="Regenerate"
            data-pr-position="top"
          />
          <Tooltip target=".regenerate-icon" className="custom-tooltip" />
        </div>
        {isLoading ? (
          <div className="horizontal-container">
            <div className="row">
              <Skeleton width="80%" className="mb-2" />
              <Skeleton width="20%" className="mb-2" />
            </div>
            <div className="row">
              <Skeleton width="30%" className="mb-2" />
              <Skeleton width="30%" className="mb-2" />
              <Skeleton width="40%" className="mb-2" />
            </div>
            <div className="row">
              <Skeleton width="40%" className="mb-2" />
              <Skeleton width="60%" className="mb-2" />
            </div>
          </div>
        ) : (
          <textarea
            className="selectednote-body td1"
            value={editableBody}
            onChange={handleBodyChange}
            onInput={adjustTextareaHeight}
            ref={textareaRef}
            disabled={isNoteGenerating}
          />
        )}
        <AiPrompt
          onSubmit={handleAskAiSubmitClick}
          rootClassName="aiprompt-root-class-name"
        />
      </div>
    </div>
  );
};

SelectedNote.defaultProps = {
  title: "SUBJECTIVE",
  body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  onClick: () => {},
};

SelectedNote.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  onClick: PropTypes.func,
};

export default SelectedNote;
