/**
 * External Dependencies
 */
import React from "react";

/**
 * Internal Dependencies
 * - Styles
 */
import "./downloads.css";

/**
 * downloads component for handling version control functionality.
 * @returns {JSX.Element} downloads component
 */
const Downloads = () => {
  // Function to get styles based on versionType
  const getStateStyles = (versionType) => {
    switch (versionType) {
      case "Beta":
        return {
          borderColor: "#074765",
          backgroundColor: "#e5f6fd",
          color: "#074765",
        };
      case "Deprecated":
        return {
          borderColor: "#724b14",
          backgroundColor: "#fff4e5",
          color: "#724b14",
        };
      case "Long-Term Support":
        return {
          borderColor: "#3e6241",
          backgroundColor: "#edf7ed",
          color: "#3e6241",
        };
      case "Incomplete":
        return {
          borderColor: "#787878",
          backgroundColor: "rgba(179, 179, 179, 0.1)",
          color: "#787878",
        };
      case "Default":
        return {
          borderColor: "#697ec7",
          backgroundColor: "#ebeefa",
          color: "#697ec7",
          pointerEvents: "none",
          cursor: "not-allowed",
        };
      case "Error":
        return {
          borderColor: "#662b2a",
          backgroundColor: "#fdeded",
          color: "#662b2a",
        };
      default:
        return {};
    }
  };

  const versionItems = [
    {
      id: 4,
      versionNumber: "3.0.0.0",
      versionDescription:
        "Added problem list and diagnose with Epic integration.",
      versionType: "Beta",
      versionMacM1DownloadUrl: "/downloads/v3.0.0.0//MacOS (M1)/Halo.zip",
      versionMacIntelDownloadUrl: "/downloads/v3.0.0.0//MacOS (Intel)/Halo.zip",
      versionWindowsDownloadUrl: "/downloads/v3.0.0.0/Windows/Halo.exe",
      versionMacM1DownloadDisplay: true,
      versionMacIntelDownloadDisplay: true,
      versionWindowsDownloadDisplay: true,
    },
    {
      id: 3,
      versionNumber: "2.0.0.0",
      versionDescription:
        "Enhanced stability. Elevated syncing support with Epic. Mac signature.",
      versionType: "Long-Term Support",
      versionMacM1DownloadUrl: "/downloads/v2.0.0.0//MacOS (M1)/Halo.zip",
      versionMacIntelDownloadUrl: "/downloads/v2.0.0.0//MacOS (Intel)/Halo.zip",
      versionWindowsDownloadUrl: "/downloads/v2.0.0.0/Windows/Halo.exe",
      versionMacM1DownloadDisplay: true,
      versionMacIntelDownloadDisplay: true,
      versionWindowsDownloadDisplay: true,
    },
    {
      id: 2,
      versionNumber: "1.0.0.1",
      versionDescription:
        "Auto-load smartlists. Prevent multiple Halo instances.",
      versionType: "Deprecated",
      versionMacM1DownloadUrl: "/downloads/v1.0.0.1/Halo.zip",
      versionWindowsDownloadUrl: "/downloads/v1.0.0.1/Halo.exe",
      versionMacM1DownloadDisplay: true,
      versionMacIntelDownloadDisplay: false,
      versionWindowsDownloadDisplay: true,
    },
    {
      id: 1,
      versionNumber: "1.0.0.0",
      versionDescription: "Initial release with core features.",
      versionType: "Deprecated",
      versionMacM1DownloadUrl: "/downloads/v1.0.0.0/Halo.zip",
      versionWindowsDownloadUrl: "/downloads/v1.0.0.0/Halo.exe",
      versionMacM1DownloadDisplay: true,
      versionMacIntelDownloadDisplay: false,
      versionWindowsDownloadDisplay: true,
    },
  ];

  return (
    <div className="downloads-container">
      <div className="downloads-downloads">
        <span className="singlelinewrap td4 downloads-title">
          Download Halo for Desktop
        </span>
        <span className="downloadsitem-text1 singlelinewrap tl1 downloads-subtitle">
          Halo for Desktop is required for EMR integration. Currently supporting
          Epic Systems.
        </span>
        <span className="downloads-line"></span>
        <div className="downloads-downloadsitemlist">
          {versionItems.map((item) => (
            <div key={item.id} className="downloadsitem-downloadsitem">
              <div className="downloadsitem-textcontainer">
                <span className="singlelinewrap td3">{`Version ${item.versionNumber}`}</span>
                <span className="downloadsitem-text1 singlelinewrap tl1">
                  {item.versionDescription}
                </span>
              </div>
              <div className="downloadsitem-visitusecontainer">
                <span className="downloadsitem-text2 td2">
                  {item.versionType}
                </span>
              </div>
              <div className="downloadsitem-statecontainer"></div>
              <div className="downloadsitem-buttoncontainer">
                <div
                  className="downloadsitem-statelabel"
                  style={getStateStyles(item.versionType)}
                >
                  <span className="downloadsitem-text3">
                    {item.versionType}
                  </span>
                </div>
                <a href={item.versionWindowsDownloadUrl} download>
                  <div
                    className={`subbutton-subbutton ${
                      item.versionWindowsDownloadDisplay ? "" : "disabled"
                    }`}
                    style={{
                      pointerEvents: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      alt="image"
                      src="/icons/windows.svg"
                      className="img1"
                    />
                    <span className="td0">Windows</span>
                  </div>
                </a>
                <a href={item.versionMacM1DownloadUrl} download>
                  <div
                    className={`subbutton-subbutton ${
                      item.versionMacM1DownloadDisplay ? "" : "disabled"
                    }`}
                    style={{
                      pointerEvents: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <img alt="image" src="/icons/apple.svg" className="img1" />
                    <span className="td0 margin-offset">MacOS (M1)</span>
                  </div>
                </a>
                <a href={item.versionMacIntelDownloadUrl} download>
                  <div
                    className={`subbutton-subbutton ${
                      item.versionMacIntelDownloadDisplay ? "" : "disabled"
                    }`}
                    style={{
                      pointerEvents: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <img alt="image" src="/icons/apple.svg" className="img1" />
                    <span className="td0 margin-offset">MacOS (Intel)</span>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Downloads;
