/**
 * Redux Reducers
 */

/**
 * Initial state import
 */
import { initialState } from "./initialState";

/**
 * Global reducer to handle application state changes.
 * @param {object} state - The current state.
 * @param {object} action - The action to handle.
 * @returns {object} The new state.
 */
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_STATE":
      return {
        ...state,
        currentState: {
          ...state.currentState,
          ...action.payload,
        },
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "ADD_VISIT":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: [action.payload, ...state.userData.visits],
        },
      };
    case "SET_VISIT":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.map((visit) =>
            visit._id === action.visitId
              ? { ...visit, ...action.payload }
              : visit,
          ),
        },
      };
    case "DELETE_VISIT":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.filter(
            (visit) => visit._id !== action.visitId,
          ),
        },
      };
    case "ADD_NOTE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.map((visit) =>
            visit._id === action.visitId
              ? {
                  ...visit,
                  notes: [...visit.notes, action.payload],
                }
              : visit,
          ),
        },
      };
    case "SET_NOTE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.map((visit) =>
            visit._id === action.visitId
              ? {
                  ...visit,
                  notes: visit.notes.map((note) =>
                    note._id === action.noteId
                      ? { ...note, ...action.payload }
                      : note,
                  ),
                }
              : visit,
          ),
        },
      };
    case "DELETE_NOTE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.map((visit) =>
            visit._id === action.visitId
              ? {
                  ...visit,
                  notes: visit.notes.filter(
                    (note) => note._id !== action.noteId,
                  ),
                }
              : visit,
          ),
        },
      };
    case "ADD_VISIT_TYPE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visitTypes: [...state.userData.visitTypes, action.payload],
        },
      };
    case "SET_VISIT_TYPE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visitTypes: state.userData.visitTypes.map((visitType) =>
            visitType._id === action.visitTypeId
              ? { ...visitType, ...action.payload }
              : visitType,
          ),
        },
      };
    case "DELETE_VISIT_TYPE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visitTypes: state.userData.visitTypes.filter(
            (visitType) => visitType._id !== action.visitTypeId,
          ),
        },
      };
    case "ADD_NOTE_TYPE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visitTypes: state.userData.visitTypes.map((visitType) =>
            visitType._id === action.visitTypeId
              ? {
                  ...visitType,
                  noteTypes: [...visitType.noteTypes, action.payload],
                }
              : visitType,
          ),
        },
      };
    case "SET_NOTE_TYPE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visitTypes: state.userData.visitTypes.map((visitType) =>
            visitType._id === action.visitTypeId
              ? {
                  ...visitType,
                  noteTypes: visitType.noteTypes.map((noteType) =>
                    noteType._id === action.noteTypeId
                      ? { ...noteType, ...action.payload }
                      : noteType,
                  ),
                }
              : visitType,
          ),
        },
      };
    case "DELETE_NOTE_TYPE":
      return {
        ...state,
        userData: {
          ...state.userData,
          visitTypes: state.userData.visitTypes.map((visitType) =>
            visitType._id === action.visitTypeId
              ? {
                  ...visitType,
                  noteTypes: visitType.noteTypes.filter(
                    (noteType) => noteType._id !== action.noteTypeId,
                  ),
                }
              : visitType,
          ),
        },
      };
    case "SET_NOTIFICATION":
      return {
        ...state,
        currentState: {
          ...state.currentState,
          currentNotifications: action.payload,
        },
      };
    case "SET_RECORDING_STATE":
      return {
        ...state,
        userData: {
          ...state.userData,
          userIsRecording: action.payload,
        },
      };
    case "ADD_DIAGNOSES":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.map((visit) =>
            visit._id === action.visitId
              ? {
                  ...visit,
                  diagnoses: [...visit.diagnoses, action.payload],
                }
              : visit,
          ),
        },
      };

    case "SET_DIAGNOSES":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.map((visit) =>
            visit._id === action.visitId
              ? {
                  ...visit,
                  diagnoses: visit.diagnoses.map((diagnosis) =>
                    diagnosis._id === action.diagnosesId
                      ? { ...diagnosis, ...action.payload }
                      : diagnosis,
                  ),
                }
              : visit,
          ),
        },
      };

    case "DELETE_DIAGNOSES":
      return {
        ...state,
        userData: {
          ...state.userData,
          visits: state.userData.visits.map((visit) =>
            visit._id === action.visitId
              ? {
                  ...visit,
                  diagnoses: visit.diagnoses.filter(
                    (diagnosis) => diagnosis._id !== action.diagnosesId,
                  ),
                }
              : visit,
          ),
        },
      };

    case "UPSERT_USER_STATS":
      const { userStatsDate, ...incomingStats } = action.payload;

      const userData = state.userData || {};
      const userStats = userData.userStats || [];

      const existingStatsIndex = userStats.findIndex(
        (stats) => stats.userStatsDate === userStatsDate,
      );

      if (existingStatsIndex >= 0) {
        const updatedUserStats = userStats.map((stats, index) =>
          index === existingStatsIndex
            ? {
                ...stats,
                ...Object.fromEntries(
                  Object.entries(incomingStats).map(([key, value]) => [
                    key,
                    stats[key] !== undefined ? stats[key] + value : value,
                  ]),
                ),
              }
            : stats,
        );

        return {
          ...state,
          userData: {
            ...userData,
            userStats: updatedUserStats,
          },
        };
      } else {
        const newStatsEntry = {
          userStatsDate,
          ...Object.fromEntries(
            Object.entries(incomingStats).map(([key, value]) => [key, value]),
          ),
        };

        return {
          ...state,
          userData: {
            ...userData,
            userStats: [...userStats, newStatsEntry],
          },
        };
      }

    default:
      return state;
  }
};

export default globalReducer;
